.loginRegisterMain {
  background-color: #fcfcfc;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}
.loginRegisterCard {
  max-width: 1200px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  margin: auto;
  position: relative;
  z-index: 1;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.loginRegisterLogo {
  width: 250px;
}

@media (max-width: 991px) {
  /* .loginRegisterMain {
    display: block;
  } */
  .loginRegisterCard {
    margin: 16px;
  }
}

@media (max-width: 600px) {
  .loginOtpModal {
    padding: 10px 40px 0px 10px !important;
    line-height: 30px;
  }
}
@media (max-width: 575px) {
  .loginRegisterLogo {
    width: 200px;
  }
  .loginRegisterCard {
    margin: 8px;
    /* overflow-x: hidden !important;
    overflow-y: auto !important; */
  }
}

.profileEmailField .MuiInputBase-root {
  border-radius: 4px 0 0 4px;
}

/* otp screen */
.otpInput {
  width: 50% !important;
  height: 3rem !important;
  max-width: 3rem;
  margin: 0 4px;
  font-size: 16px;
}
.profilePic {
  position: relative;
  width: 250px;
  height: 250px;
}
.profilePicClick {
  position: absolute;
  background: #e9f4ff;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  padding: 6px;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.profilePicClick:hover {
  background: #d6e6f5;
}
/* .profilePicClick .icon {
  width: 30px;
  height: 30px;
} */
.profilePic input {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

/* login and register page background */

.loginRegisterMain {
  color: #999;
  text-align: center;
  background: url("../../media/images/loginBg.png") repeat 0 0;
  animation: bg-scrolling 5s infinite;
  animation-timing-function: linear;
  position: relative;
}
.loginRegisterMain:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
  25% {
    background-position: 0 50px;
  }
  75% {
    background-position: 50px 0;
  }
  100% {
    background-position: -50px -50px;
  }
}

/* Verify email page */

.verifyEmailPage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px !important;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  margin: auto;
  position: relative;
  z-index: 1;
  text-align: center;
  overflow-y: auto;
}
.box-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.emailVerified .animate {
  width: 200px;
  height: 200px;
}
.emailVerified .animate path {
  animation: dash 1.5s linear both infinite;
  animation-delay: 1s;
}
.verifyEmailCard {
  border: none;
  padding: 24px;
  width: 100%;
  max-width: 500px;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 210;
  }

  75% {
    stroke-dashoffset: -220;
  }

  100% {
    stroke-dashoffset: -205;
  }
}

.loaderOTP {
  position: relative;
}
.loaderOTP::after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  position: absolute;
  background: currentColor;
  bottom: 3px;
  right: -8px;
  animation: animloader 1s linear infinite;
}
@keyframes animloader {
  0% {
    box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 10px 0 rgb(0, 0, 0, 0.6), 20px 0 rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 10px 0 rgb(0, 0, 0, 0.6), 20px 0 rgb(0, 0, 0, 0.6);
  }
}
