@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "react-toastify/dist/ReactToastify.css";

:root {
  --theme-color: #253b74;
  --blue: #2b6aaa;
  --black: #000000;
  --white: #ffffff;
  --danger: #d01d23;
  --text-area: #d0d7de;
  --orange: #f3863e;

  --black-200: rgba(0, 0, 0, 0.2);
  /* TOAST  VARIABLE */
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #e7f8ff;
  --toastify-color-success: #f3ffff;
  --toastify-color-warning: #fff7ec;
  --toastify-color-error: #feeeef;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-border-color-info: #1799ce;
  --toastify-icon-border-color-success: #0d8f88;
  --toastify-icon-border-color-warning: #f08034;
  --toastify-icon-border-color-error: #f48391;
  --toastify-toast-min-height: 50px;
  --toastify-font-family: "Source Sans Pro";
  /* TOAST  VARIABLE END */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
  /* background-color: #f2f4ff !important; */
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

[placeholder] {
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
}

.modal-header {
  font-size: 18px !important;
  font-weight: bold !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
  transition: background-color 9999s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--black) !important;
}

.topBar {
  min-height: 75px;
}

.positionRelative {
  position: relative;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.react-datepicker-popper {
  position: relative;
  z-index: 21 !important;
}

.danger {
  color: var(--danger);
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-help {
  cursor: help !important;
}

.image {
  object-fit: cover;
  max-width: 100%;
}

.m-4 {
  margin: 4 !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.p-4 {
  padding: 4 !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.dashedBorder {
  border-style: dashed !important;
  color: #e0e0e0;
}

.titleWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.twoLineEllipse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .titleWrap2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

.responsiveMenuIcon {
  display: none !important;
}

.search_input input {
  padding: 0;
}

.disableBtn:disabled {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.26) !important;
  color: var(--white) !important;
  /* pointer-events: unset !important; */
}

.outlineDisableBtn:disabled {
  cursor: not-allowed !important;
  /* pointer-events: unset !important; */
}

.outlineDisableBtn:disabled svg {
  color: rgba(0, 0, 0, 0.26);
}

.iconDisableBtn:disabled svg {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.26) !important;
  pointer-events: unset !important;
}

.tablePagination {
  padding: 2px 16px;
  background-color: var(--blue);
  color: var(--white);
}

.tablePagination .MuiTablePagination-toolbar,
.tablePagination .MuiTablePagination-toolbar svg {
  color: var(--white);
}

.tablePagination .MuiTablePagination-toolbar .MuiTablePagination-actions .Mui-disabled>svg {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.image {
  object-fit: cover;
  max-width: 100%;
}

.modal-header {
  font-size: 24px !important;
  /* color: #158582; */
}

.mobModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.pageMainCardBox,
.cardTable {
  padding: 2rem;
}

.hideInDesktop,
.hideInDesktop991 {
  display: none !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiTooltip-popper .MuiTooltip-arrow {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}

.inputStyling {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8.5px 14px;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}

.inputCustomLabels {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: var(--white);
  padding: 0 5px;
}

.fileCustomLabels {
  position: absolute;
  top: -20%;
  right: 2%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: var(--white);
  padding: 0 5px;
}

/* 
button:disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: auto !important;
} */

.grayBg {
  background: #f9f9f9;
}

.inputCustomLabels-1 {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #f9f9f9;
  padding: 0 5px;
}

.inputCustomLabels-2 {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #eeeeee;
  padding: 0 5px;
}

.notifyCheckbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.notifyMenu ul {
  max-width: 320px;
  min-width: 320px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #1d9142;
  fill: none;
  animation: stroke 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--white);
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #1d9142;
  animation: fill 0.8s ease-in-out 0.8s forwards, scale 0.6s infinite ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.6s infinite cubic-bezier(0.65, 0, 0.45, 1) 1.6s forwards;
}

.checkmark__cross {
  stroke: var(--danger);
  fill: var(--danger);
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #1d9142;
  }
}

.checkIn-checkOut-input .MuiInputBase-root,
.checkIn-checkOut-input .MuiInputBase-root input {
  border-radius: 4px 0 0 4px;
}

.checkIn-checkOut-input .Mui-focused fieldset {
  border-width: 1px !important;
}

/* .f-modal-alert {
  background-color: var(--white);
  box-shadow: 0 0 10px 2px var(--black-200);
  border-radius: 4px;
  margin: 20px;
  padding: 50px;
} */
.f-modal-alert .f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 100px;
  margin: 0 auto 20px;
  padding: 0;
  position: relative;
  width: 100px;
}

.f-modal-alert .f-modal-icon.f-modal-success,
.f-modal-alert .f-modal-icon.f-modal-error {
  border-color: rgba(29, 145, 66, 0.2);
}

/* .f-modal-alert .f-modal-icon.f-modal-success:after,
.f-modal-alert .f-modal-icon.f-modal-error:after,
.f-modal-alert .f-modal-icon.f-modal-success:before,
.f-modal-alert .f-modal-icon.f-modal-error:before {
  background: var(--white);
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
} */
.f-modal-alert .f-modal-icon.f-modal-success:before,
.f-modal-alert .f-modal-icon.f-modal-error:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}

.f-modal-alert .f-modal-icon.f-modal-success:after,
.f-modal-alert .f-modal-icon.f-modal-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
}

.f-modal-alert .f-modal-icon.f-modal-success .f-modal-placeholder,
.f-modal-alert .f-modal-icon.f-modal-error .f-modal-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(29, 145, 66, 0.2);
  box-sizing: content-box;
  height: 100px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 100px;
  z-index: 2;
}

/* .f-modal-alert .f-modal-icon.f-modal-success .f-modal-fix,
.f-modal-alert .f-modal-icon.f-modal-error .f-modal-fix {
  background-color: var(--white);
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
} */
.f-modal-alert .f-modal-icon.f-modal-success .f-modal-line,
.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line {
  background-color: #1d9142;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
}

.f-modal-alert .f-modal-icon.f-modal-success .f-modal-line.f-modal-tip,
.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 35px;
}

.f-modal-alert .f-modal-icon.f-modal-success .f-modal-line.f-modal-long,
.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 59px;
}

.f-modal-alert .f-modal-icon.f-modal-error {
  border-color: rgba(208, 29, 35, 0.2);
}

.f-modal-alert .f-modal-icon.f-modal-error .f-modal-x-mark {
  display: block;
  position: relative;
  z-index: 2;
}

.f-modal-alert .f-modal-icon.f-modal-error .f-modal-placeholder {
  border: 4px solid rgba(200, 0, 0, 0.2);
}

.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line {
  background-color: var(--danger);
  top: 47px;
  width: 66px;
}

.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-left {
  left: 17px;
  transform: rotate(45deg);
}

.f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-right {
  right: 16px;
  transform: rotate(-45deg);
}

.f-modal-alert .f-modal-icon.f-modal-warning {
  border-color: #f8bb86;
}

.f-modal-alert .f-modal-icon.f-modal-warning:before {
  animation: pulseWarning 2s linear infinite;
  background-color: var(--white);
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.f-modal-alert .f-modal-icon.f-modal-warning:after {
  background-color: var(--white);
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.f-modal-alert .f-modal-icon.f-modal-warning .f-modal-body {
  background-color: #f8bb86;
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
  z-index: 2;
}

.f-modal-alert .f-modal-icon.f-modal-warning .f-modal-dot {
  background-color: #f8bb86;
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
  z-index: 2;
}

.f-modal-alert .f-modal-icon+.f-modal-icon {
  margin-top: 50px;
}

.animateSuccessTip {
  animation: animateSuccessTip 1.5s infinite ease-in;
}

.animateSuccessLong {
  animation: animateSuccessLong 1.5s infinite ease-in;
}

.f-modal-icon.f-modal-success.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
  animation: animateErrorIcon 0.5s infinite;
}

.animateXLeft {
  animation: animateXLeft 1.5s infinite ease-in;
}

.animateXRight {
  animation: animateXRight 1.5s infinite ease-in;
}

.scaleWarning {
  animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {

  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 27px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 35px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {

  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 67px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 59px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {

  0%,
  5% {
    transform: rotate(-45deg);
  }

  100%,
  12% {
    transform: rotate(-405deg);
  }
}

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateXLeft {

  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }

  84% {
    left: 14px;
    top: 47px;
    width: 66px;
  }

  100% {
    left: 17px;
    top: 48px;
    width: 66px;
  }
}

@keyframes animateXRight {

  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }

  84% {
    right: 14px;
    top: 47px;
    width: 66px;
  }

  100% {
    right: 16px;
    top: 48px;
    width: 66px;
  }
}

@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulseWarning {
  0% {
    background-color: var(--white);
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    background-color: var(--white);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    background-color: #f8bb86;
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }

  100% {
    background-color: #f8bb86;
  }
}

.respTable {
  overflow: auto;
}

.respTable table {
  min-width: max-content;
}

.customTextarea {
  padding: 16px 50px 16px 16px;
  border-color: var(--text-area);
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}

.attachFileBtn {
  border: 1px solid var(--text-area);
  background-color: var(--text-area);
  border-radius: 50%;
  padding: 4px;
  transition: 0.5s all;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.attachFileBtn:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.attachFileBtn svg {
  transform: rotate(-45deg);
  transition: 0.5s all;
}

.attachFileBtn:hover svg {
  color: var(--text-area);
}

.chatImgDeleteBtn {
  background: #e9f4ff !important;
  border-radius: 50% !important;
  padding: 4px !important;
  position: absolute !important;
  top: 4px;
  right: 4px;
  z-index: 2;
  transition: 0.5s all;
}

.chatImgDeleteBtn:hover {
  background-color: #e0e0e0 !important;
}

/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.decryptTextarea {
  padding: 16px;
  border-color: var(--text-area);
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}

.searchBar {
  max-width: 500px;
}

.eventManageCardsList {
  padding-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
}

.eventManageCards {
  flex: 1;
  min-width: fit-content;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  position: relative;
}

.count-line,
.list-count-line {
  border-left: 1px solid #e0e0e0;
}

.eventManageCardsCountMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.eventManageCardsCount {
  flex: 1;
  padding: 0 8px;
  min-width: fit-content;
  text-align: center;
}

/* Media queries */
/* Tablets */
@media (max-width: 1200px) {
  .cardTable {
    padding: 0;
  }

  .eventManageCardsList {
    display: block;
    width: 100%;
    float: left;
    padding-bottom: 16px;
  }

  .eventManageCards {
    width: 100%;
    float: left;
  }

  .eventManageCards:first-child {
    margin-bottom: 15px;
  }

  .list-count-line {
    border-left: none;
  }
}

@media (max-width: 991px) {
  .sectionPad {
    padding: 8px !important;
  }

  .respBottomSpace {
    margin-bottom: 48px !important;
  }

  .hideInResponsive991 {
    display: none !important;
  }

  .showInResponsive991 {
    display: block !important;
  }

  .MuiDialog-container .MuiPaper-root {
    margin: 5px !important;
  }

  .responsive-head {
    width: 100%;
    justify-content: space-between !important;
  }

  .responsiveMenuIcon {
    display: flex !important;
  }
}

@media (max-width: 768px) {
  .app .btn-toggle {
    display: inline-flex;
  }

  .hideInResponsive {
    display: none !important;
  }

  .showInResponsive {
    display: block !important;
  }

  .searchBar {
    width: 100%;
  }

  .searchBar {
    max-width: none !important;
  }
}

/* Mobile phones */
@media (max-width: 599px) {
  .pageMainCardBox>.MuiPaper-root {
    padding: 15px !important;
  }

  .cardTable>.MuiPaper-root {
    padding: 4px !important;
  }

  .MuiDialogContent-root {
    padding: 10px !important;
  }

  .wrapbtn {
    display: inline-block;
  }

  .wrapbtn button {
    padding: 3px 13px;
    font-size: 14px;
  }

  .addfield {
    font-size: 23px !important;
  }
}

@media (max-width: 575px) {
  .pageMainCardBox {
    padding: 0.5rem;
  }

  .cardTable {
    padding: 0;
  }

  .eventManageCardsCountMain {
    justify-content: space-between;
  }

  .eventManageCardsCount {
    flex: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .eventManageCardsCount .event-card-title {
    font-size: 1rem;
    line-height: 1;
    font-weight: 600;
  }

  .eventManageCardsCount .event-card-info {
    font-size: 18px;
    line-height: 1.235;
    font-weight: 600;
  }

  .emcc-4,
  .emcc-5,
  .emcc-6,
  .emcc-7 {
    width: 50%;
  }

  .emcc-6.count-line {
    border-left: none;
  }
}

@media (max-width: 480px) {
  .eventManageCards:nth-child(-n + 5) {
    width: 100%;
  }

  .eventManageCards:nth-child(odd) {
    border-right: none;
  }
}

@media (max-width: 480px) {
  .eventManageCardsCount {
    width: 100%;
  }

  .count-line {
    border-left: none;
  }
}

.ticketPhoto {
  position: relative !important;
  width: fit-content !important;
  margin: auto !important;
}

.thumbNailPhoto {
  position: relative;
  margin: auto;
  overflow-x: auto;
  max-width: 300px;
}

.colorPhoto button,
.ticketPhoto button,
.thumbNailPhoto button,
.youtubeurl button {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 4px;
  transition: 0.5s all;
}

.colorPhoto button:hover,
.ticketPhoto button:hover,
.thumbNailPhoto button:hover,
.youtubeurl button:hover {
  background-color: var(--white);
}

.colorPhoto img {
  max-width: 100%;
  max-height: 150px;
  display: block;
}

.ticketPhoto img {
  width: 100%;
  max-width: 320px;
  max-height: 300px;
  display: block;
}
.ticketPhoto.horizontal img {
  width: 100%;
  max-width: 420px;
  max-height: 350px;
  display: block;
}

.dragDropFile {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  position: relative;
  width: 100%;
  overflow: hidden;
}

.dragDropFile input {
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.anchorLink {
  text-decoration: underline;
  font-weight: bold;
  color: var(--orange);
  transition: 0.8s all;
}

.anchorLink:hover {
  color: #4c8ac7;
}

.crownIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: -9px;
  left: -10px;
  transform: rotate(-15deg);
}

.disabledPassEyeIcon:disabled {
  opacity: 0.5;
}

.label-blue {
  color: var(--blue);
  background: rgba(43, 106, 170, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-red {
  color: var(--danger);
  background: rgba(208, 29, 35, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-green {
  color: #1d9142;
  background: rgba(29, 145, 66, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-gray {
  color: #3d3d3d;
  background: rgba(61, 61, 61, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-lightBlue {
  color: #4c8ac7;
  background: rgba(76, 137, 199, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-orange {
  color: var(--orange);
  background: rgba(243, 134, 62, 0.2);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.label-black {
  color: var(--black);
  background: var(--black-200);
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.customChip,
.customChipOutlined {
  border-radius: 16px;
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 24px;
}

.customChip p,
.customChipOutlined p {
  font-size: 13px;
  font-weight: 600;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

/* New Dashboard CSS */
.newDashCard {
  border-radius: 6px;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
}

/* .newDashCardWhiteBox { */
/* background: var(--white); */
/* border: 1px solid rgba(245, 245, 245, 0.5); */
/* } */
.newDashFlexCardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 6px;
  column-gap: 8px;
}

.newDashCardsRight {
  position: relative;
  padding: 16px;
  text-align: right;
  overflow: hidden;
}

.newDashChartBoxButton {
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  background: #f0f0f0;
  color: #909090;
  cursor: pointer;
}

.newDashChartBoxButton.active {
  background: #2e93fa;
  color: var(--white);
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.MuiFormHelperText-filled {
  position: absolute;
  bottom: -18px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--black);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--black);
}

@media screen and (max-width:539px) {
  .MuiDialog-scrollPaper {
    width: 100% !important;
  }

  .MuiDialog-paper {
    width: 100% !important;
  }
}

.ticketContainer {
  margin-top: 20px;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
}

.ticket {
  animation: bouncingCard 0.6s ease-out infinite alternate;
  border: 2px solid var(--theme-color);
  color: darkslategray;
  border-radius: 12px;
}

/* Ticket Content */
.ticketTitle {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 12px 16px 4px;
}

.ticketContainer hr {
  border: 1px solid #efefef;
}

.ticketDetail {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 4px 16px;
}

.ticketSubDetail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 12px 16px;
}

.ticketSubDetail .code {
  margin-right: 24px;
}

/* Ticket Ripper */
.ticketRip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circleLeft {
  width: 12px;
  height: 24px;
  background-color: var(--theme-color);
  border-radius: 0 12px 12px 0;
}

.ripLine {
  width: 100%;
  border-top: 3px solid var(--theme-color);
  border-top-style: dashed;
}

.circleRight {
  width: 12px;
  height: 24px;
  background-color: var(--theme-color);
  border-radius: 12px 0 0 12px;
}

.box-space {
  padding: 36px;
}

@media screen and (max-width:899px) {
  .box-space {
    padding: 16px;
  }
}

.notificationBadge span {
  background: #f3863e;
  font-weight: bold;
  color: #fff;
}

/* ====================== TOAST  css START  ======================*/
.Toastify__close-button {
  color: var(--black);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #d5161f;
  border: 2px solid var(--toastify-icon-border-color-error);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-icon-border-color-success);
  border: 2px solid var(--toastify-icon-border-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-icon-border-color-info);
  border: 2px solid var(--toastify-icon-border-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-icon-border-color-warning);
  border: 2px solid var(--toastify-icon-border-color-warning);
}

.Toastify__toast {
  border-radius: 15px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    right: 0;
    margin: 0;
    top: 15px;
    padding: 0 10px;
  }
}

/* ====================== TOAST  css END  ======================*/

@media screen and (max-width:599px) {
  .bottom-space {
    margin-bottom: 15px !important;
  }
}

/* slider gallery images  */
.slide {
  display: none;
  text-align: center;
}

.slide.active-anim {
  display: block;
  height: 300px;
  width: 300px;
}

.slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.slider-btn {
  position: absolute !important;
  transform: translate(0, -50%);
  top: 50%;
}

.slider-btn.next {
  right: 10px;
}

.slider-btn.prev {
  left: 10px;
}

.slide.active {
  display: block;
}

.slide.active img {
  max-width: 100%;
  max-height: calc(100vh - 64px);
  object-fit: contain;
  display: block;
}

.dragging {
  opacity: 0.1;
  background-color: #f2f2f2;
}

.loading-top {
  position: relative;
}

.loading-top::after {
  content: "please wait";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgb(22 59 114 / 9%);
  left: 0;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  text-shadow: 1px 0.5px 3px black;
  text-transform: uppercase;
}

.color-guid span {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
}

.color-guid li:first-child span {
  background-color: #646464;
}

.color-guid li:nth-child(2) span {
  background-color: #1d9142;
}

.color-guid li {
  gap: 6px;
  width: auto;
  padding: 3px 9px;
  margin: 0 0px 0 10px;
  background: #f5f5f5;
  border-radius: 5px;
}

.color-guid {
  display: inline-flex;
}

@media only screen and (max-width: 600px) {
  .media-filter {
    padding-left: 0 !important;
    padding-top: 24px;
  }

  .notify_parent {
    flex-wrap: wrap;
  }
}

@media screen and (max-width:500px) {
  .notify-card {
    flex-wrap: wrap;
  }

  .notify_boxtext {
    padding: 10px 0px !important;
  }
}

/* .notify_boxtext {
  width: calc(100% - 124px);
} */
/* UTILS CLASSES */
.capitalize {
  text-transform: capitalize;
}

.reporttable thead th,
.reporttable tfoot td {
  padding: 6px 10px;
}

.reporttable tbody td {
  padding: 6px 10px;
}

.text-success {
  color: #1d9142;
}

.rotate-180 {
  rotate: 180deg;
}

.mx-width {
  width: 100%;
  max-width: 800px;
}

.tagDelete {
  float: left;
  background: #69626d;
  color: White;
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px 5px 0;
  cursor: pointer;
}


/* testingform */
#testform label {
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-bottom: 0.25rem;
  color: rgb(33, 37, 41);
  font-weight: 400;
  font-family: system-ui;
}

#testform .submit {
  font-family: system-ui;
  text-transform: capitalize;
}

#testform input::placeholder,
#testform textarea::placeholder {
  color: #b8c9c1;
}

#testform select {
  color: #b8c9c1;
}

#testform {
  max-width: 600px;
  margin: 0 auto;
}

#testform input[type="text"],
#testform .frmselect,
#testform input[type="email"],
#testform input[type="tel"],
#testform input[type="number"],
#testform select,
#testform textarea,
#testform input[type="file"],
#testform input[type="date"] {
  display: block;
  width: 100%;
  font-family: system-ui;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
#testform input:focus,#testform textarea:focus {
 outline:0 !important;
 border-color: #86b7fe !important;
}
.frmselect:focus {
  border: 2px solid blue !important;
}


.frmselect {
  position: relative;
}
.frmselect .open-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid #ccc;
}
#testform .radiocheck {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

#testform .radiocheck label {
  margin-bottom: 0;
}

#testform select,
#testform input[type="date"] {
  appearance: none;
}

#testform select {
  background: url(../src/media/images/arrow-down-3101.svg);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 100% center;
  background-origin: content-box;
  cursor: pointer;
}

#testform input[type=file]::file-selector-button {
  border: 2px solid #e9ecef;
  border-right: 1px solid #d3d5d6;
  padding: 0.4em 0.6em;
  background-color: #e9ecef;
  transition: 1s;
  font-family: system-ui;
}

#testform input[type=file] {
  padding: 0;
}

.fs-12{
font-size: 12px;
}
.text-muted{
  color: #6c757d;
}

.auto-form-btn{
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-family: sans-serif; /* Default font family (optional, adjust based on needs) */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid #263a74;
    border-radius: 0.375rem;
    background-color: #263a74;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(0, 0, 0, .075);
    opacity: 1; 
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  
}
.auto-form-btn:focus {
  box-shadow: 0 0 0 0.25rem #212529; 
}

.button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.addfield {
  line-height: 0;
  font-size: 28px;
  padding: 0 8px 0px 0px;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 32px;
  max-height: 16px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider (background) style */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* background-color: #ccc; */
  border: 1px solid #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* Circle (thumb) inside the slider */
.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 2px;
  bottom: 2px;
  background-color: #ccc;
  transition: 0.4s;
}

/* When the switch is checked, change the background color and move the circle */
input:checked+.slider {
  background-color: #0d6efd;
  border-color:transparent;
}

input:checked+.slider:before {
  transform: translateX(15px);
  background-color: #fff;
}

/* Optionally, add styles for the ON/OFF text */
.switch-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.date-picker-container {
  position: relative;
}

#datepicker {
  padding-left: 37px !important;
}

#datepicker:focus {
  outline: none;
  border-color: #007bff;
}

#datepicker::placeholder {
  color: #aaa;
}

svg.h-20px.w-20px.react-datepicker__calendar-icon {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 21px;
}
.w-100{
  width: 100% !important;
}
.react-date-picker-view-form input{
padding-left: 30px !important;
}

.react-datepicker__calendar-icon{
  width: 1.2em !important;
    height: 1.2em !important;
}
.text-danger{
  color: var(--danger);
}

/* Custom Styles for MyFileInput component */

/* Wrapper */
.mb-3 {
  margin-bottom: 1rem;
}

/* Label */
label.mb-1 {
  font-size: 1rem;
  font-weight: 600;
}

label span.text-danger {
  color: #dc3545;
}

/* Input Styles */
input[type="file"] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: #fff;
}

input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Description Text */
small.text-muted {
  font-size: 0.875rem;
  color: #6c757d;
}

/* Error Message */
.text-danger {
  font-size: 0.875rem;
  color: #dc3545;
}

/* Preview Section (Images/PDFs) */
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.my-3 {
  margin-block: 1rem;
}

.w-100px {
  width: 100px;
}

.h-100px {
  height: 100px;
}

.border {
  border: 1px solid #ccc;
}

.border-1 {
  border-width: 1px;
}

.border-secondary {
  border-color: #6c757d;
}

.rounded {
  border-radius: 0.25rem;
}

.object-fit-contain {
  object-fit: contain;
}

.supportImgCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #dc3545;
}

/* Icons */
.w-50px {
  width: 50px;
}

.h-50px {
  height: 50px;
}

.w-15px {
  width: 15px;
}

.h-15px {
  height: 15px;
}

.text-muted {
  color: #6c757d;
}

.fs-12 {
  font-size: 12px;
}

/* File icon */
.pdf-icon {
  font-size: 2rem;
  color: #6c757d;
  text-align: center;
}

.image-preview {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}



/* update design */
#testform span.text-muted {
  display: block;
}

.input-group-text{
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  border-radius: 0.375rem;
  opacity: 0.6;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.remove-border{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.frmselect svg {
  vertical-align: text-top;
  margin-left: 8px;
}
.no-options{
  text-align: center;
  margin: 13px;
  color: #b8c9c1;
  font-weight: 600;
  font-size: 17px;
}
.nodata {
  background: #c6c6f745;
  border-radius: 50%;
  padding: 50px;
}
.form_info {
  font-size: clamp(0.7rem, 0.8rem + 2.9231vw, 2rem) !important;
}
.tag {
  background-color: #455c89;
  padding: 0px 10px 3px;
  border-radius: 30px;
  margin-bottom: 6px;
  color: #fff;
  line-height: normal;
}
.User_img {
  width: 100px;
  border-radius: 4px;
}
.first-latter-capital{
  text-transform: capitalize;
}
.first-latter-capital::first-letter{
  text-transform: capitalize;
}
.sticky-column{
  position:  sticky;
right : 0;
z-index: 10; 
background-color: var(--blue);
}
.sticky-value-column{
  position:  sticky;
  right : 0;
  z-index: 10; 
  background-color: var(--white);

}
@media only screen and (max-width: 1760px) {

.sticky-value-column::before, .sticky-value-column::before,
.sticky-column::before, .sticky-column::before
{
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-right: 1px solid var(--borderColor);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}
}
.invoice-service-menu .MuiList-root.MuiList-padding.MuiMenu-list{ 
  max-height: 200px;
}
.offertoggle {
  visibility: hidden;
}
.max-width {
  display: block;
  white-space: pre-line;
}

.additional-form-label::first-letter{
  text-transform: capitalize;
}
/*=========================================================
REACT DATEPICKER CSS
========================================================= */
.react-datepicker__year-option:last-child:hover,
.react-datepicker__year-option:first-child:hover{
  background: transparent;
}
.react-datepicker__year-dropdown:last-child,
.react-datepicker__year-dropdown:first-child{
  padding: 10px !important;
}
.react-datepicker__navigation--years{
  height: 15px !important;
  width: 15px !important;
  background-color: transparent !important;
  border-right: 3px solid #000 !important;
  border-top: 3px solid #000 !important;
}
.react-datepicker__navigation--years-upcoming{
  transform: rotate(-45deg) !important;
}
.react-datepicker__navigation--years-previous{
  transform: rotate(135deg) !important;
}
@media screen and (max-width:899px) {
  .benifit {
    display: none;
  }
  .offertoggle {
    visibility: visible;
  }
}
.offertoggle img {
  width: 88px;
}