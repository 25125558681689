.globalLoader {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: fixed;
  z-index: 1024;
  top: 0;
  left: 0;
}
.loaderBack {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fit_to_content{
  position: relative;
  width: 100%;
  height:  calc(100% - 75px);
}
/* .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #253b74;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
} */

.loader {
  width: calc(100px - 24px);
  height: 50px;
  position: relative;
  animation: flippx 2s infinite linear;
}
.loader:before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2b6aaa;
  transform-origin: -24px 50%;
  animation: spin 1s infinite linear;
  box-shadow: rgba(43, 106, 170, 0.56) 0px 22px 70px 4px;
}
.loader:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #253b74;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: rgba(37, 59, 116, 0.56) 0px 22px 70px 4px;
}

@keyframes flippx {
  0%,
  49% {
    transform: scaleX(1);
  }
  50%,
  100% {
    transform: scaleX(-1);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
